body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.query-cards .Polaris-Card {
  min-height: 224px;
}

.counter-graph {
  font-weight: normal;
  font-size: 32px;
  text-align: center;
  line-height: 112px;
}

.search-preview-image {
  display: block;
  text-align: center;
}

.search-preview-title {
  text-align: center;
  display: inline-block;
  max-width: 50%;
  margin: 0 auto;
  text-decoration: none;
}

.search-preview-product {
  text-decoration: none;
  color: #777;
  font-size: 16px;
  padding: 36px 12px;
  text-align: center;
}

.search-preview-price {
  text-align: center;
  display: block;
  margin-top: 12px;
  font-size: 24px;
}

.search-preview-panel .facet-value {
  cursor: pointer;
}

.Polaris-Stack {
  /*align-items: center !important; */
}
